// import $ from 'jquery';

window.addEventListener('DOMContentLoaded', () => {

    //Hamburger

    const hamburger = document.querySelector('.hamburger');
    const menu = document.querySelector('.menu');
    const menuClose = document.querySelector('.menu__close');
    const overlay = document.querySelector('.menu__overlay');
    const menuItem = document.querySelectorAll('.menu__link');

    function open() {
        hamburger.classList.add('hamburger--active');
        menu.classList.add('menu--active');
    };

    function close() {
        hamburger.classList.remove('hamburger--active');
        menu.classList.remove('menu--active');
    };

    hamburger.addEventListener('click', open);
    menuClose.addEventListener('click', close);
    overlay.addEventListener('click', close);

    menuItem.forEach(item => {
        item.addEventListener('click', () => {
            open();
            close();
        })
    });

    //Rating

    const percent = document.querySelectorAll('.skills__ability-percent');
    const lines = document.querySelectorAll('.skills__ability-line span');

    percent.forEach((el, i) => {
        lines[i].style.width = el.textContent;
    });

    //Scroll

    const sidepanel = document.querySelector('.sidepanel');
    const svg = document.querySelectorAll('.sidepanel__link svg path');
    const devider = document.querySelector('.sidepanel__devider');
    const text = document.querySelector('.sidepanel__text');

    window.addEventListener('scroll', () => {

        if (window.pageYOffset < 730) {
            svg.forEach(el => {
                el.style.fill = 'white';
            })

            sidepanel.style.top = '';
            devider.style.backgroundColor = 'white';
            text.style.color = 'white';
        } else {
            svg.forEach(el => {
                el.style.fill = 'black';
            })

            sidepanel.style.top = 250 + 'px';
            devider.style.backgroundColor = 'black';
            text.style.color = 'black';
        }
    });

    //Form

    function checkMailInputs() {
        const mailInputs = document.querySelectorAll('[type="email"]');

        mailInputs.forEach(input => {
            input.addEventListener('keypress', (e) => {
                if (e.key.match(/[^a-z 0-9 @ \.]/ig)) {
                    e.preventDefault();
                }
            });
        });
    };

    checkMailInputs();

    // $('.contacts__form').submit(function (e) {
    //     e.preventDefault();

    //     $.ajax({
    //         type: 'POST',
    //         url: 'mailer/smart.php',
    //         data: $(this).serialize()
    //     }).done(function () {
    //         $('form').trigger('reset');
    //     });
    //     return false;
    // });

    const postData = async (url, data) => {
        const res = await fetch(url, {
            method: 'POST',
            body: data
        });

        return await res.text();
    };

    const form = document.querySelector('form');

    function bindsPostData(form) {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const formData = new FormData(form);

            postData('mailer/smart.php', formData)
                .then(data => {
                    console.log(data);
                }).catch(() => {
                    console.log('error');
                }).finally(() => {
                    form.reset();
                });
        });
    };

    bindsPostData(form);

    // Portfolio

    const portfolioImg = document.querySelectorAll('.portfolio__item');

    portfolioImg.forEach(item => {
        if (item.getAttribute('href') === '#') {
            item.style.filter = 'grayscale(100%)'
        }
    });

});




